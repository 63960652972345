module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","icon":"src/images/favicons/favicon-96x96.png","icons":[{"src":"src/images/favicons/android-icon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"src/images/favicons/android-icon-96x96.png","sizes":"96x96","type":"image/png"},{"src":"src/images/favicons/android-icon-36x36.png","sizes":"36x36","type":"image/png"},{"src":"src/images/favicons/android-icon-48x48.png","sizes":"48x48","type":"image/png"},{"src":"src/images/favicons/android-icon-72x72.png","sizes":"72x72","type":"image/png"},{"src":"src/images/favicons/android-icon-144x144.png","sizes":"144x144","type":"image/png"},{"src":"src/images/favicons/android-icon-192x192.png","sizes":"192x192","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"786574623b3cb4dc6934da769a9229e3"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
